import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Otherservice from "../components/otherservice";
import { IoArrowUpOutline } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import { variantPages } from "../animations/pageTransition";
import Form from "../components/form";
import MetaTag from "../components/metaTag";
import { certificatesSEO } from "../components/data/seo";
import {
  containercertandsubs,
  preview,
  stock,
  option,
  price,
  list,
  background,
  title,
  description,
  vector,
  otherservices,
  btn,
} from "../styles/pages/service.module.scss";
import "swiper/css";
import "swiper/css/pagination";

function Certificates() {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <MetaTag data={certificatesSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
      >
        <div className={containercertandsubs}>
          <div className={preview}>
            <h2 className={title}>Сертификаты</h2>
            <p className={description}>Для вас и ваших близких от 1000₽</p>
            <StaticImage
              src="../images/services/9.jpeg"
              alt="обложка для абонементов клинтим"
              className={background}
            />
          </div>
          <div className={stock}>
            <h3 className={title}>
              Приобрести сертификат на ежедневную уборку
            </h3>
            <button className={btn} onClick={() => setOpenForm(true)}>
              Заказать сертификат <IoArrowUpOutline />
            </button>
            <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
          </div>
          <div
            className={option}
            style={{
              background: "linear-gradient(180deg, #4449C5 0%, #FFA764 100%)",
            }}
          >
            <h2 className={title}>Думаете, что подарить близким?</h2>
            <p className={list}>
              <span>
                Вы можете приобрести подарочный сертификат номиналом 1000₽,
                3000₽, 5000₽.
              </span>
              <br />
              <br />
              Отличным подарком на День Рождения, Новый год или 8 марта будет
              Подарочный сертификат на уборку! Ваши близкие непременно оценят
              такой подарок. Ведь Вы дарите не только чистоту и уют, что важно
              для любого из нас, но и драгоценное время!
              <br />
              <br />
              <span>
                Оплатить подарочный сертификат можно любым удобным способом.
                Наличными при получении, безналичным расчетом, а также мы
                принимаем online оплату.
              </span>
              <br />
              Чтобы начать использование сертификата, необходима предварительная
              запись по телефону. При этом достаточно назвать номер сертификата.
            </p>
          </div>
          <div
            className={price}
            style={{
              background: "linear-gradient(64.7deg, #D15842 0%, #F17F29 100%)",
            }}
          >
            <h2 className={title}>Условия использования:</h2>
            <p className={list}>
              <span>
                Сертификат действует в течение 3-х месяцев с момента
                приобретения.
              </span>{" "}
              Минимальный номинал от 1000₽.
              <br />
              Сертификат может распространяться, как на одну уборку, так и на
              несколько.
              <br />
              <br />
              Для приобретения сертификата достаточно оставить заявку на сайте
              или в мессенджере. Наш менеджер свяжется с вами и подробно
              проконсультирует. Подарочный сертификат распространяется на любые
              виды услуг клининговой компании: уборка помещений, мойка окон,
              химчистка ковров на дому. Доставка сертификата в подарок
              осуществляется по Красноярску по договоренности.{" "}
              <span>
                Также, возможно предоставление сертификата в электронном виде.
              </span>
            </p>
            <img src="/cartvector/1.svg" alt="фон вектор" className={vector} />
          </div>
        </div>
        <h3 className={otherservices}>Наши услуги:</h3>
        <Otherservice hide={false} />
        <AnimatePresence>
          {openForm && (
            <Form
              service="сертификат на уборку"
              closeForm={() => setOpenForm(false)}
            />
          )}
        </AnimatePresence>
      </motion.section>
    </>
  );
}

export default Certificates;
